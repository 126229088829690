import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useSitesUIContext } from "../SitesUIContext";

const prepareFilter = (queryParams, values) => {
  const {searchText,county } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  // Filter by all fields
  if (searchText) {
    filter.siteName = searchText;
    filter.siteDescription = searchText;
    
  }
  if(county){
    filter.county = county;
  }
  newQueryParams.filter = filter;
  return newQueryParams;
};

export function SitesFilter({ listLoading }) {
  // Sites UI Context
  const sitesUIContext = useSitesUIContext();
  const sitesUIProps = useMemo(() => {
    return {
      queryParams: sitesUIContext.queryParams,
      setQueryParams: sitesUIContext.setQueryParams,
    };
  }, [sitesUIContext]);

  // queryParams, setQueryParams,
  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(sitesUIProps.queryParams, values);
    if (!isEqual(newQueryParams, sitesUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      sitesUIProps.setQueryParams(newQueryParams);
    }
  };


  const countyOptions = [
    { code:1, name:"Mombasa (County)"},
    { code:2, name:"Kwale"},
    { code:3, name:"Kilifi"},
    { code:4, name:"Tana River"},
    { code:5, name:"Lamu"},
    { code:6, name:"Taita–Taveta"},
    { code:7, name:"Garissa"},
    { code:8, name:"Wajir"},
    { code:9, name:"Mandera"},
    { code:10, name:"Marsabit"},
    { code:11, name:"Isiolo"},
    { code:12, name:"Meru"},
    { code:13, name:"Tharaka-Nithi"},
    { code:14, name:"Embu"},
    { code:15, name:"Kitui"},
    { code:16, name:"Machakos"},
    { code:17, name:"Makueni"},
    { code:18, name:"Nyandarua"},
    { code:19, name:"Nyeri"},
    { code:20, name:"Kirinyaga"},
    { code:21, name:"Murang'a"},
    { code:22, name:"Kiambu"},
    { code:23, name:"Turkana"},
    { code:24, name:"West Pokot"},
    { code:25, name:"Samburu"},
    { code:26, name:"Trans-Nzoia"},
    { code:27, name:"Uasin Gishu"},
    { code:28, name:"Elgeyo-Marakwet"},
    { code:29, name:"Nandi"},
    { code:30, name:"Baringo"},
    { code:31, name:"Laikipia"},
    { code:32, name:"Nakuru"},
    { code:33, name:"Narok"},
    { code:34, name:"Kajiado"},
    { code:35, name:"Kericho"},
    { code:36, name:"Bomet"},
    { code:37, name:"Kakamega"},
    { code:38, name:"Vihiga"},
    { code:39, name:"Bungoma"},
    { code:40, name:"Busia"},
    { code:41, name:"Siaya"},
    { code:42, name:"Kisumu"},
    { code:43, name:"Homa Bay"},
    { code:44, name:"Migori"},
    { code:45, name:"Kisii"},
    { code:46, name:"Nyamira"},
    { code:47, name:"Nairobi (County)"},
  ];

  return (
    <>
      <Formik
        initialValues={{
          searchText: "",
          county:""
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
                <small className="form-text text-muted">
                  <b>Search</b> in all fields
                </small>
              </div>
              <div className="col-lg-4">
                <select
                    className="form-control"
                    name="county"
                    placeholder="Filter by County"
                    onChange={(e) => {
                      setFieldValue("county", e.target.value);
                      handleSubmit();
                    }}
                    onBlur={handleBlur}
                    value={values.county}
                  >
                    <option value="">All</option>
                    {countyOptions.map((x,idx)=>{
                        return <option key={idx} value={x.code}>{x.name}</option>
                    })}
                  </select>
                  <small className="form-text text-muted">
                    <b>Filter</b> by county
                  </small>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
