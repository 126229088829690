import React, { useMemo } from "react";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { RenderMenu } from "./RenderMenu";

export function AsideMenu({ isActive, toRender, permissions }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true),
    };
  }, [uiService]);

  return (
    <div className={`tab-pane fade ${isActive && "show active"}`}>
      <div className="aside-menu-wrapper flex-column-fluid px-10 pb-5">
        <div id="kt_aside_menu" data-menu-vertical="1" className={`aside-menu  min-h-lg-800px ${layoutProps.asideClassesFromConfig}`} {...layoutProps.asideMenuAttr} >
          {(toRender === 'kt_aside_tab_overview') ? <RenderMenu layoutProps={layoutProps} permissions={permissions} tab={'tabOverview'} /> : ''}
          {(toRender === 'kt_aside_tab_licensing') ? <RenderMenu layoutProps={layoutProps} permissions={permissions} tab={'tabLicensing'} /> : ''}
          {(toRender === 'kt_aside_tab_users') ? <RenderMenu layoutProps={layoutProps} permissions={permissions} tab={'tabUsers'} /> : ''}
          {(toRender === 'kt_aside_tab_accidents_complaints') ? <RenderMenu layoutProps={layoutProps} permissions={permissions} tab={'tabAccidents'} /> : ''}
        </div>
      </div>
    </div>
  );
}