export const SiteStatusCssClasses = ["danger","info","info","info","success","info","info","info","info","info", "success"];
export const SiteStatusTitles = ["Draft","In Review","In Review","In Review", "In Committee", "Committee Done", "Director Stage", "Approved"];
export const TariffStatusTitles = ["Missing","Draft","In Review","In Review", "In Committee", "Committee Done", "Director Stage", "Approved"];
export const PermitStatusTitles = ["Missing","Draft","In Review","In Review", "In Committee", "Committee Done", "Director Stage", "Approved"];
export const LicenseStatusTitles = ["Missing","Draft","In Review","In Review", "In Committee", "Committee Done", "Director Stage", "Approved"];
export const defaultSorted = [{ dataField: "firstName", order: "asc" }];



export const sizePerPageList = [
  { text: "5", value: 5 },
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
  { text: "250", value: 250 },
  { text: "500", value: 500 },
];
export const initialFilter = {
  filter: {
    siteName: "",
    siteDescription: "",
    userId: "",
    county: "",
  },
  sortOrder: "asc", // asc||desc
  sortField: "siteName",
  pageNumber: 1,
  pageSize: 10
};
