/* Application process UI */
import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../_helpers/AssetsHelpers";
import { /*Typography,*/ Tooltip } from "@material-ui/core";
// import { withStyles } from "@material-ui/styles";

export function ApplicationProcess({ activeStep }) {
    const [show, toggleShow] = useState(true);
    return (
        <>
            <div className={"row approcess pt-5 pb-5 pb-md-20 " + (show ? '' : 'd-none')} style={{ backgroundColor: '#f9fbfd', borderRadius: '3px' }}>
                <Tooltip title="Hide this, for now">
                    <span className="d-none close text-dark-50 text-hover-danger" onClick={() => toggleShow(false)}>&times;</span>
                </Tooltip>
                <div className="col-md-12 pb-0 pb-md-20">
                    <h4 className="font-weight-boldest text-dark-50 text-center mb-0">Licence Application Process</h4>
                </div>
                <div className="col-md-4 pr-md-0">
                    {/* <CustomTooltip title={getTooltipContent(1)} interactive> */}
                    <div className={"my-2 sect " + (activeStep === 1 ? "active" : "")}>
                        <div className="p-5 d-block text-center">
                            <div className="step d-md-none"><div>1</div></div>
                            <strong className="d-block">Expression of Interest for Site Exclusivity</strong>

                            <hr className="opacity-70" />
                            <small style={{ marginBottom: '4px', display: 'block', fontSize: '10px' }}>Approved by</small>
                            <small className="aby" style={{ backgroundColor: '#37ad47', color: '#fff', padding: '2px 8px', borderRadius: '80px' }}>Cabinet Secretary</small>
                            <small style={{ marginBottom: '2px', marginTop: '10px', display: 'block', fontSize: '10px' }}>Approval/rejection within 30 days of receipt of complete application.<br />Valid for 12 months.</small>
                        </div>
                        <img alt="" className="arr bottom d-none d-md-block" src={toAbsoluteUrl("/media/svg/process/arrow-bottom.svg")} />
                    </div>
                    {/* </CustomTooltip> */}
                </div>
                <div className="col-md-4 pr-md-0">
                    {/* <CustomTooltip title={getTooltipContent(2)} interactive> */}
                    <div className={"my-2 sect " + (activeStep === 2 || activeStep === 3 ? "active" : "")}>
                        <div className="row">
                            <div className="p-5 d-block text-center col-md-12">
                                <div className="step d-md-none"><div>2</div></div>
                                <strong className="d-block">Tariff Application</strong>
                                
                                <hr className="opacity-70" />
                                <small style={{ marginBottom: '4px', display: 'block', fontSize: '10px' }}>Approved by</small>
                                <small className="aby" style={{ backgroundColor: '#37ad47', color: '#fff', padding: '2px 8px', borderRadius: '80px' }}>EPRA</small>
                                <small style={{ marginBottom: '2px', marginTop: '10px', display: 'block', fontSize: '10px' }}>To be submitted within 12 months of the MoU agreed with the community.<br />EPRA notification of receipt within 15 days. <br />Approval/rejection within 60 days</small>
                            </div>
                        </div>
                        <img alt="" className="arr bottom d-none d-md-block"  src={toAbsoluteUrl("/media/svg/process/arrow-bottom.svg")} />
                    </div>
                    {/* </CustomTooltip> */}
                </div>
                <div className="col-md-4">
                    {/* <CustomTooltip title={getTooltipContent(4)} interactive> */}
                    <div className={"my-2 sect " + (activeStep === 4 ? "active" : "")}>
                        <div className="p-5 d-block text-center">
                            <div className="step d-md-none"><div>4</div></div>
                            <strong className="d-block">Licence Application</strong>
                            
                            <hr className="opacity-70" />
                            <small style={{ marginBottom: '4px', display: 'block', fontSize: '10px' }}>Approved by</small>
                            <small className="aby" style={{ backgroundColor: '#37ad47', color: '#fff', padding: '2px 8px', borderRadius: '80px' }}>EPRA</small>
                            <small style={{ marginBottom: '2px', marginTop: '10px', display: 'block', fontSize: '10px' }}>To be submitted within 12 months of the date of tariff approval. <br />EPRA notification of receipt within 15 days. <br />Approval/rejection within 60 days.</small>
                        </div>
                    </div>
                    {/* </CustomTooltip> */}
                </div>
            </div>
            {/* <img alt=""className="image img img-fluid" src="/media/diagrams/process-diagram-mini-grid.png" alt="Process Diagram" /> */}
        </>
    )
}
