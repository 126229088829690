/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
//import { NavLink } from "react-router-dom";
//import SVG from "react-inlinesvg";
import {  checkIsActive, appMenus } from "../../../../_helpers"; //toAbsoluteUrl,
import { Nav, NavDropdown } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export function RenderEpraMenu({ permissions }) {
    const location = useLocation();
    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(location, url, false) ? ` ${!hasSubmenu && "active"}` : "";
    };

    return (
        <>
            <Nav.Link   as={NavLink} to="/">Mini Grids Dashboard</Nav.Link>
            {
                appMenus.map((menu, i) => {
                    return menu.section.map((sect, j) => {
                        return (
                            <React.Fragment key={'submenu-' + j}>
                                {
                                    (permissions.includes(sect.permission) || sect.permission === "") ?
                                    (
                                        <NavDropdown title={sect.title} id="primary-nav-dropdown" className={`${getMenuItemActive(sect.page, true)}`}>
                                            {sect.submenu.map((sub, k) => {
                                                return (
                                                    <NavDropdown.Item  as={NavLink} to={sub.page} key={'tubmenu-' + k} className={`${getMenuItemActive(sub.page, false)}`}>{sub.title}</NavDropdown.Item>
                                                )
                                            })}
                                        </NavDropdown>
                                    ): null
                                }
                            </React.Fragment>
                        )
                    })
                })
            }

        </>
    );
}