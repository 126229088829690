// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../Licensing/_redux/applications/applicationsActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_epra/_helpers";
import * as uiHelpers from "../SitesUIHelpers";
import { Pagination } from "../../../../_epra/_partials/controls";
import { useSitesUIContext } from "../SitesUIContext";
import * as columnFormatters from "./column-formatters";
export function SitesTable() {
  // Sites UI Context
  const sitesUIContext = useSitesUIContext();
  const sitesUIProps = useMemo(() => {
    return {
      ids: sitesUIContext.ids,
      setIds: sitesUIContext.setIds,
      queryParams: sitesUIContext.queryParams,
      setQueryParams: sitesUIContext.setQueryParams,
    };
  }, [sitesUIContext]);



  // Getting curret state of sites list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.applications }),
    shallowEqual
  );

  const { expressionsOfInterestTotalCount:totalCount, expressionsOfInterest: entities, listLoading } = currentState;

 
  const { user_id: userId } = useSelector(
    (state) => ({
        user_id: state.auth.user.id || "",
    }),
    shallowEqual
  );
  // Sites Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    sitesUIProps.setIds([]);
    // server call by queryParams
    let query = sitesUIProps.queryParams;
    query.filter.userId = userId;
    dispatch(actions.findExpressionsOfInterest(sitesUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sitesUIProps.queryParams, dispatch]);


  useEffect(() => {
    dispatch(actions.updateAllExpressionsOfInterestDependencies([]));
  }, [dispatch]);

  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "ID",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "siteName",
      text: "Site",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.SiteColumnFormatter
    },
    {
      dataField: "location",
      text: "County",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.CountyColumnFormatter
    },
    {
      dataField: "createdOn",
      text: "Date Created",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.DateCreatedColumnFormatter
    },
    {
      dataField: "isApproved",
      text: "EOI Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.StatusColumnFormatter
    },
    {
      dataField: "tariffApplicationStep",
      text: "Tariff Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.TariffColumnFormatter
    },
    {
      dataField: "licenseApplicationStep",
      text: "Licence Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: columnFormatters.LicenseColumnFormatter
    },
    
    // {
    //   dataField: "step",
    //   text: "Step",
    //   sort: false,
    //   sortCaret: sortCaret,
    // },
    // {
    //   dataField: "action",
    //   text: "Actions",
    //   classes: "text-right pr-0",
    //   headerClasses: "text-right pr-3",
    //   style: {
    //     minWidth: "100px",
    //   },
    // },
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: sitesUIProps.queryParams.pageSize,
    page: sitesUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="_id"
                data={entities === null || entities === undefined ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  sitesUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: sitesUIProps.ids,
                  setIds: sitesUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
