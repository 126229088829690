import React from "react";
import { Container, Nav, NavDropdown, Navbar } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../_helpers";
import { RenderEpraMenu } from "../aside/aside-menu/RenderEpraMenu";
import * as auth from "../../../../app/modules/Auth/_redux/authRedux";
import { NavLink } from "react-router-dom";

export function EpraHeader({ user }) {

    // console.log(user_permissions);
    const user_permissions = auth.getUserPermissions(user?.roles);

    const logoutClick = () => {
        window.location.href = "/logout";
    };

    return (<>
        <div className="" style={{ width: '100%' }}>
            <Container>
                <div className="row">
                    <div className="col-md-12 py-5" style={{ background: `url(${toAbsoluteUrl("/media/bg/curved-banner.png")}) top right no-repeat` }}>
                        <a href="/"><img src={toAbsoluteUrl("/media/logos/logo-text.png")} alt="logo" style={{ height: "60px" }} /></a>
                    </div>
                </div>
            </Container>
        </div>
        <Navbar collapseOnSelect variant="dark" expand="lg" style={{ background: '#3ab54a', }}>
            <Container>
                <Navbar.Toggle aria-controls="epra-navbar-nav" />
                <Navbar.Collapse id="epra-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="https://portal.erc.go.ke:8443/site/about">About Us</Nav.Link>
                        <NavDropdown title="Licence Management" id="epra-nav-dropdown">
                            <NavDropdown.Item href="https://portal.erc.go.ke:8443/site/new-application">New Licence Application</NavDropdown.Item>
                            <NavDropdown.Item href="https://portal.erc.go.ke:8443/site/my-licences">My Licenses</NavDropdown.Item>
                            <NavDropdown.Item href="https://portal.erc.go.ke:8443/site/history">Application History</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="https://portal.erc.go.ke:8443/petroleumstock/stockreporting">Petroleum Info &amp; Cert</NavDropdown.Item>
                            <NavDropdown.Item href="https://portal.erc.go.ke:8443/commencementofwork/index?id=0">Completion Certificate</NavDropdown.Item>
                            <NavDropdown.Item href="https://portal.erc.go.ke:8443/commencementofwork/reviews">Certificate Reviews</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item href="https://portal.erc.go.ke:8443/renewablereturnsdata">R.E. Returns</NavDropdown.Item>
                            <NavDropdown.Item href="https://portal.erc.go.ke:8443/ppareports">Power Purchase Agreement</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="Petrol Stations" id="epra-nav-dropdown">
                            <NavDropdown.Item href="https://portal.erc.go.ke:8443/keptap/stations/index">Stations</NavDropdown.Item>
                            <NavDropdown.Item href="https://portal.erc.go.ke:8443/keptap/prices/index">Pump Prices</NavDropdown.Item>
                            <NavDropdown.Item href="https://portal.erc.go.ke:8443/keptap/station-sales/index">Monthly Sales</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="https://portal.erc.go.ke:8443/complaints/create">File Complaints</Nav.Link>
                        <Nav.Link href="https://portal.erc.go.ke:8443/accidents/create">Report Accidents</Nav.Link>
                        <Nav.Link href="https://portal.erc.go.ke:8443/corruption/create">Report Corruption</Nav.Link>
                        <Nav.Link href="https://portal.erc.go.ke:8443/surveys">Surveys</Nav.Link>
                        <NavDropdown title="Energy Management" id="epra-nav-dropdown">
                            <NavDropdown.Item href="https://portal.erc.go.ke:8443/energy">Energy</NavDropdown.Item>
                        </NavDropdown>
                        {/* <Nav.Link href="https://portal.erc.go.ke:8443/site/verify-licence">Verify Licenses</Nav.Link> */}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        <div className="">
            <Navbar collapseOnSelect variant="dark" expand="lg" style={{ background: '#ecc31b', }}>
                <Container>
                    {/* <Navbar.Brand href="/"><strong>Mini Grids</strong></Navbar.Brand> */}
                    <Navbar.Toggle aria-controls="primary-navbar-nav" />
                    <Navbar.Collapse id="primary-navbar-nav">
                        <Nav className="mr-auto">
                            <RenderEpraMenu permissions={user_permissions} />
                        </Nav>
                        <Nav className="">
                            <NavDropdown title={user?.firstname + " " + user?.lastname} id="primary-nav-dropdown" className="usr font-weight-bolder">
                                {/* <NavDropdown.Item href="https://portal.erc.go.ke:8443/site/account">Update Profile</NavDropdown.Item> */}
                                <NavDropdown.Item as={NavLink} to="/profile/personal-info">My Profile</NavDropdown.Item>
                                <NavDropdown.Item as={NavLink} to="/profile/change-password">Change Password</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item as={NavLink} to="/profile/corporate-profile">Corporate Profile</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item onClick={logoutClick} className="text-danger">Logout</NavDropdown.Item>
                            </NavDropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    </>)
}