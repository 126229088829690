export const appMenus = [
    {
        title: "Licensing",
        icon: "/media/svg/icons/Files/Selected-file.svg",
        page: "/licensing",
        tab: "tabLicensing",
        permission: "",
        section: [
            {
                title: "My Applications",
                permission: "",
                submenu: [
                    {
                        title: "Expressions of Interest",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/licensing/applications/my-expressions-of-interest",
                        permission: "",
                    },
                    {
                        title: "Tariff",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/licensing/applications/my-tariff-applications",
                        permission: "",
                    },
                    {
                        title: "Licence",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/licensing/applications/my-license-applications",
                        permission: "",
                    },
                    {
                        title: "Licence Payments",
                        icon: "/media/svg/icons/Shopping/Credit-card.svg",
                        page: "/licensing/applications/my-license-payments",
                        permission: "",
                    },
                    {
                        title: "Monthly Reports",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/licensing/reports/my-monthly-reports",
                        permission: "",
                    },
                ],
            },
            {
                title: "Review EOIs/ Sites",
                permission: "accessToExpressionOfInterestRead",
                submenu: [
                    {
                        title: "All Applications",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/licensing/applications/all-expression-of-interest-applications",
                        permission: "accessToExpressionOfInterestRead",
                    },
                    {
                        title: "Pending Acknowledgement",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/licensing/applications/pending-expression-of-interest-acknowledgement",
                        permission: "accessToExpressionOfInterestRead",
                    },
                    {
                        title: "Pending Review",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/licensing/applications/pending-expression-of-interest-approval",
                        permission: "accessToExpressionOfInterestReview",
                    },
                    {
                        title: "Committee Stage",
                        icon: "/media/svg/icons/Files/Folder-check.svg",
                        page: "/licensing/applications/approved-expressions-of-interest",
                        permission: "accessToExpressionOfInterestRead",
                    },
                    {
                        title: "Final Approval Stage",
                        icon: "/media/svg/icons/Files/Folder-check.svg",
                        page: "/licensing/applications/pending-director-expression-of-interest-approval",
                        permission: "accessToExpressionOfInterestDirectorApproval",
                    },
                    {
                        title: "Approved",
                        icon: "/media/svg/icons/Files/Folder-check.svg",
                        page: "/licensing/applications/director-approved-expression-of-interest",
                        permission: "accessToExpressionOfInterestRead",
                    },
                    {
                        title: "Expressions of Interest Report",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/licensing/applications/reports/expressions-of-interest-report",
                        permission: "accessToExpressionOfInterestRead",
                    },
                    {
                        title: "Tariff  Report",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/licensing/applications/reports/tariff-applications-report",
                        permission: "accessToExpressionOfInterestRead",
                    },
                    {
                        title: "Licence Report",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/licensing/applications/reports/license-applications-report",
                        permission: "accessToExpressionOfInterestRead",
                    },
                    {
                        title: "My Approval Committees",
                        icon: "/media/svg/icons/Files/Folder-check.svg",
                        page: "/licensing/settings/committees/my-committees/",
                        permission: "accessToExpressionOfInterestRead",
                    },
                ],
            },
            {
                title: "Reports",
                permission: "accessToTariffApplicationsRead",
                submenu: [
                    {
                        title: "Expressions of Interest Report",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/licensing/applications/reports/expressions-of-interest-report",
                        permission: "accessToTariffApplicationsRead",
                    },
                    {
                        title: "Tariff  Report",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/licensing/applications/reports/tariff-applications-report",
                        permission: "accessToTariffApplicationsRead",
                    },
                    {
                        title: "Licence Report",
                        icon: "/media/svg/icons/Files/Downloads-folder.svg",
                        page: "/licensing/applications/reports/license-applications-report",
                        permission: "accessToTariffApplicationsRead",
                    },
                    {
                        title: "All Tariff Applications",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/licensing/applications/all-tariff-applications",
                        permission: "accessToTariffApplicationsRead",
                    },
                   
                    {
                        title: "All Licence Applications",
                        icon: "/media/svg/icons/Files/Folder-cloud.svg",
                        page: "/licensing/applications/all-license-applications",
                        permission: "accessToLicenseApplicationsRead",
                    },
                ],
            },
        ],
    },
    {
        title: "User Management",
        icon: "/media/svg/icons/Communication/Group.svg",
        page: "/user-management",
        tab: "tabUsers",
        permission: "accessToUsersRead",
        section: [
            {
                title: "User Management",
                permission: "accessToUsersRead",
                submenu: [
                    {
                        title: "Manage Users",
                        icon: "/media/svg/icons/Communication/Group.svg",
                        page: "/user-management/users",
                        permission: "accessToUsersRead",
                    },
                    {
                        title: "Manage Roles",
                        icon: "/media/svg/icons/Code/Compiling.svg",
                        page: "/user-management/roles",
                        permission: "accessToRolesRead",
                    },
                    {
                        title: "Manage Committees",
                        icon: "/media/svg/icons/Code/Control.svg",
                        page: "/licensing/settings/approval-committees",
                        permission: "accessToRolesRead",
                    },
                ],
            },
        ],
    },
];