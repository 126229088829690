import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_epra/layout";
import { DashboardPage } from "./pages/DashboardPage";

const UsersPage = lazy(() =>
  import("./modules/UserManagement/UserManagement")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const AccidentsPage = lazy(() =>
  import("./modules/AccidentsManagement/AccidentManagement")
);
const LicensingPage = lazy(() =>
  import("./modules/Licensing/LicenseManagement")
);
const ComplaintsPage = lazy(() =>
  import("./modules/ComplaintsManagement/ComplaintManagement")
);
const Settings = lazy(() =>
  import("./modules/Settings/Settings")
);
export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (<>
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect from="/mini-grid/register/:param/24" to="/licensing/applications/my-expressions-of-interest" />
        }
        {
          <Redirect from="/mini-grid/register/:param/23" to="/licensing/applications/my-tariff-applications" />
        }
        {
          <Redirect from="/mini-grid/register/:param/22" to="/licensing/applications/my-license-applications" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <Route path="/user-management" component={UsersPage} />
        <Route path="/profile" component={UserProfilepage} />
        <Route path="/licensing" component={LicensingPage} />        
        <Route path="/accident-management" component={AccidentsPage} />
        <Route path="/complaint-management" component={ComplaintsPage} />
        <Route path="/settings" component={Settings} />
        <Route path="/helpdesk" component={Settings} />
        <Redirect to="/error/err" />
      </Switch>
    </Suspense>
  </>);
}
