import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./SitesUIHelpers";

const SitesUIContext = createContext();

export function useSitesUIContext() {
  return useContext(SitesUIContext);
}

export const SitesUIConsumer = SitesUIContext.Consumer;

export function SitesUIProvider({sitesUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initSite = {
    id: undefined,
    _id: "",
    SiteName: "",
    siteDescription: "",
    step: "",
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initSite
  };

  return <SitesUIContext.Provider value={value}>{children}</SitesUIContext.Provider>;
}