// please be familiar with react-bootstrap-table-next column formaters
// https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html?selectedKind=Work%20on%20Columns&selectedStory=Column%20Formatter&full=0&addons=1&stories=1&panelRight=0&addonPanel=storybook%2Factions%2Factions-panel
import React from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { countyOptions, reviewSteps } from "../../../../../_epra/_assets/lists";
import {
  SiteStatusCssClasses
} from "../../SitesUIHelpers";

export function StatusColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `${
      SiteStatusCssClasses[row.applicationStep]
    } `;
  };
  return (
    <Badge variant={getLabelCssClasses()}>
      {reviewSteps[row.applicationStep]}
    </Badge>
  );
}

export function TariffColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `${
      SiteStatusCssClasses[row.tariffApplicationStep]
    } `;
  };
  return (
    <Badge variant={getLabelCssClasses()}>
      {row.tariffApplicationId===""?"Not Added":(reviewSteps[row.tariffApplicationStep])}
    </Badge>
  );
}

export function LicenseColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return `${
      SiteStatusCssClasses[row.licenseApplicationStep]
    } `;
  };
  return (
    <Badge variant={getLabelCssClasses()}>
      {row.licenseApplicationId===""?"Not Added":(reviewSteps[row.licenseApplicationStep])}
    </Badge>
  );
}


export function SiteColumnFormatter(cellContent, row) {
  const getLabelCssClasses = () => {
    return ``;
  };
  return (
    <Link className={getLabelCssClasses()} to={`/licensing/applications/expression-of-interest-profile/${row._id}`}>
      {row.siteName}
    </Link>
  );
}

export function CountyColumnFormatter(cellContent, row) {



  return (
    <span>{row&&row.location[0].county&&countyOptions.map((x,idx)=>{
      if(x.code === parseInt(row.location[0].county)){
          return <strong key={idx}>{x.name}</strong>
      } else {
          return <span key={idx}></span>;
      }
  })}</span>
  );
}
export function DateCreatedColumnFormatter(cellContent, row) {
  const showDate = (timestamp) => {
    return new Date(parseFloat(timestamp)).toLocaleDateString("en-US",{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }); 
  }
  return (
     <>{showDate(row.createdOn)}</> 
  );
}

