import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_epra/_partials/controls";
import { SitesFilter } from "./sites-filter/SitesFilter";
import { SitesTable } from "./sites-table/SitesTable";
import { SitesGrouping } from "./sites-grouping/SitesGrouping";
import { useSitesUIContext } from "./SitesUIContext";

export function SitesCard() {
  const sitesUIContext = useSitesUIContext();
  const sitesUIProps = useMemo(() => {
    return {
      ids: sitesUIContext.ids,
      newSiteButtonClick: sitesUIContext.newSiteButtonClick,
    };
  }, [sitesUIContext]);

  return (
    <Card>
      <CardHeader title="Sites list">
        <CardHeaderToolbar>
          {/* <button
            type="button"
            className="btn btn-primary"
            onClick={sitesUIProps.newSiteButtonClick}
          >
            New Site
          </button> */}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <SitesFilter />
        {sitesUIProps.ids.length > 0 && <SitesGrouping />}
        <SitesTable />
      </CardBody>
    </Card>
  );
}
