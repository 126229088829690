import React from "react";
import { SitesLoadingDialog } from "./sites-loading-dialog/SitesLoadingDialog";
import { SitesUIProvider } from "./SitesUIContext";
import { SitesCard } from "./SitesCard";

export function SitesPage({ history }) {

  const sitesUIEvents = {
  }



  return (
    <SitesUIProvider sitesUIEvents={sitesUIEvents}>
      <SitesLoadingDialog />
      <SitesCard />
    </SitesUIProvider>
  );
}
