import * as requestFromServer from "./applicationsCrud";
import { applicationsSlice, callTypes } from "./applicationsSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = applicationsSlice;

export const fetchApplications = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findApplications(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.applicationsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find applications";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchApplication = id => dispatch => {
  if (!id) {
    return dispatch(actions.applicationFetched({ applicationForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getApplicationById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const application = response.data;
      dispatch(actions.applicationFetched({ applicationForEdit: application }));
    })
    .catch(error => {
      error.clientMessage = "Can't find application";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const findConstructionPermit = id => dispatch => {
  if (!id) {
    return dispatch(actions.constructionPermitFetched({ constructionPermit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getconstructionPermitById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const newConstructionPermit = response.data;
      dispatch(actions.constructionPermitFetched({ constructionPermit: newConstructionPermit }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Construction Permit";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const findConstructionPermitByUserId = userId => dispatch => {
  if (!userId) {
    return dispatch(actions.constructionPermitFetched({ constructionPermit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getconstructionPermitByUserId(userId)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const newConstructionPermit = response.data;
      dispatch(actions.constructionPermitFetched({ constructionPermit: newConstructionPermit }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Construction Permit";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const findConstructionPermitByEOIId = expressionOfInterestId => dispatch => {
  if (!expressionOfInterestId) {
    return dispatch(actions.constructionPermitFetched({ constructionPermit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getconstructionPermitByEOI(expressionOfInterestId)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const newConstructionPermit = response.data;
      dispatch(actions.constructionPermitFetched({ constructionPermit: newConstructionPermit }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Construction Permit";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const acknowledgeLicenseApplication = licenseApplicationReview => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .acknowledgeLicenseApplication(licenseApplicationReview)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { licenseApplicationReview } = response.data;
      dispatch(actions.licenseApplicationReviewCreated({ licenseApplicationReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Licence Application Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const approveLicenseApplication = licenseApplicationReview => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .approveLicenseApplication(licenseApplicationReview)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { licenseApplicationReview } = response.data;
      dispatch(actions.licenseApplicationReviewCreated({ licenseApplicationReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Licence Application Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const voteLicenseApplication= licenseApplicationReviewForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .voteLicenseApplication(licenseApplicationReviewForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { licenseApplicationReview } = response.data;
      dispatch(actions.licenseApplicationReviewCreated({ licenseApplicationReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create License Application Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const committeeVoteLicenseApplication = licenseApplicationReviewForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .committeeVoteLicenseApplication(licenseApplicationReviewForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { licenseApplicationReview } = response.data;
      dispatch(actions.licenseApplicationReviewCreated({ licenseApplicationReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create License Application Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const directorApproveLicenseApplication = licenseApplicationReviewForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .directorApproveLicenseApplication(licenseApplicationReviewForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { licenseApplicationReview } = response.data;
      dispatch(actions.licenseApplicationReviewCreated({ licenseApplicationReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create License Application Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const acknowledgeTariffApplication = tariffAplicationReview => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .acknowledgeTariffApplication(tariffAplicationReview)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { tariffApplicationReview } = response.data;
      dispatch(actions.tariffApplicationReviewCreated({ tariffApplicationReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Tariff Application Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const approveTariffApplication = tariffAplicationReview => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .approveTariffApplication(tariffAplicationReview)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { tariffApplicationReview } = response.data;
      dispatch(actions.tariffApplicationReviewCreated({ tariffApplicationReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Tariff Application Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const voteTariffApplication= tariffApplicationReviewForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .voteTariffApplication(tariffApplicationReviewForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { tariffApplicationReview } = response.data;
      dispatch(actions.tariffApplicationReviewCreated({ tariffApplicationReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Tariff Application Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const committeeVoteTariffApplication = tariffApplicationReviewForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .committeeVoteTariffApplication(tariffApplicationReviewForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { tariffApplicationReview } = response.data;
      dispatch(actions.tariffApplicationReviewCreated({ tariffApplicationReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Tariff Application Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const directorApproveTariffApplication = tariffApplicationReviewForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .directorApproveTariffApplication(tariffApplicationReviewForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { tariffApplicationReview } = response.data;
      dispatch(actions.tariffApplicationReviewCreated({ tariffApplicationReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Tariff Application Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const approveConstructionPermit = constructionPermitReviewForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .approveConstructionPermit(constructionPermitReviewForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { constructionPermitReview } = response.data;
      dispatch(actions.constructionPermitReviewCreated({ constructionPermitReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create construction permit review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const findMonthlyPerformanceReport = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findMonthlyPerformanceReport(params)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { monthlyReport } = response.data;
      dispatch(actions.monthlyReportCreated({ monthlyReport }));
    })
    .catch(error => {
      error.clientMessage = "Can't find monthly report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const addMonthlyReport = monthlyReportForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .addMonthlyReport(monthlyReportForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { monthlyReport } = response.data;
      dispatch(actions.monthlyReportCreated({ monthlyReport }));
    })
    .catch(error => {
      error.clientMessage = "Can't create monthly report";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const acknowledgeExpressionOfInterest = expressionOfInterestReviewForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .acknowledgeExpressionOfInterest(expressionOfInterestReviewForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { expressionOfInterestReview } = response.data;
      dispatch(actions.expressionOfInterestReviewCreated({ expressionOfInterestReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Expresion of Interest Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const approveExpressionOfInterest = expressionOfInterestReviewForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .approveExpressionOfInterest(expressionOfInterestReviewForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { expressionOfInterestReview } = response.data;
      dispatch(actions.expressionOfInterestReviewCreated({ expressionOfInterestReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Expresion of Interest Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};



export const voteExpressionOfInterest = expressionOfInterestReviewForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .voteExpressionOfInterest(expressionOfInterestReviewForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { expressionOfInterestReview } = response.data;
      dispatch(actions.expressionOfInterestReviewCreated({ expressionOfInterestReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Expresion of Interest Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const committeeVoteExpressionOfInterest = expressionOfInterestReviewForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .committeeVoteExpressionOfInterest(expressionOfInterestReviewForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { expressionOfInterestReview } = response.data;
      dispatch(actions.expressionOfInterestReviewCreated({ expressionOfInterestReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Expresion of Interest Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const directorApproveExpressionOfInterest = expressionOfInterestReviewForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .directorApproveExpressionOfInterest(expressionOfInterestReviewForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { expressionOfInterestReview } = response.data;
      dispatch(actions.expressionOfInterestReviewCreated({ expressionOfInterestReview }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Expresion of Interest Review";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};



export const createTariffApplication = tariffApplicationForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createTariffApplication(tariffApplicationForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { tariffApplication } = response.data;
      dispatch(actions.tariffApplicationCreated({ tariffApplication }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Tariff Application";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createTariffApplications = tariffApplicationsForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createTariffApplications(tariffApplicationsForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { tariffApplications } = response.data;
      dispatch(actions.tariffApplicationsCreated({ tariffApplications }));
      return tariffApplications;
    })
    .catch(error => {
      error.clientMessage = "Can't create Tariff Applications";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateTariffApplicationsByEOI = tariffApplicationsForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateTariffApplicationsByEOI(tariffApplicationsForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { tariffApplications } = response.data;
      dispatch(actions.tariffApplicationsUpdatedbyEOI({ tariffApplications }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Tariff Applications";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const findTariffApplication = id => dispatch => {
  if (!id) {
    return dispatch(actions.tariffApplicationFetched({ tariffApplication: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTariffApplicationById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const newTariffApplication = response.data;
      dispatch(actions.tariffApplicationFetched({ tariffApplication: newTariffApplication }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Tariff Applications";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const findTariffApplicationByConstructionPermitId = constructionPermitId => dispatch => {
  if (!constructionPermitId) {
    return dispatch(actions.tariffApplicationFetched({ tariffApplication: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTariffApplicationByConstructionPermitId(constructionPermitId)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const newTariffApplication = response.data;
      dispatch(actions.tariffApplicationFetched({ tariffApplication: newTariffApplication }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Tariff Application";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const findTariffApplicationByExpressionOfInterestId = expressionOfInterestId => dispatch => {
  if (!expressionOfInterestId) {
    return dispatch(actions.tariffApplicationFetched({ tariffApplication: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getTariffApplicationByExpressionOfInterestId(expressionOfInterestId)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const newTariffApplication = response.data;
      dispatch(actions.tariffApplicationFetched({ tariffApplication: newTariffApplication }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Tariff Application";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateTariffApplicationModelByExpressionOfInterestId = expressionOfInterestId => dispatch => {
  if (!expressionOfInterestId) {
    return dispatch(actions.tariffApplicationFetched({ tariffApplication: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateTariffApplicationModelByExpressionOfInterestId(expressionOfInterestId)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const newTariffApplication = response.data.tariffApplication;
      dispatch(actions.tariffApplicationFetched({ tariffApplication: newTariffApplication }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Tariff Application";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateTariffApplication = tariffApplicationForUpdate => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateTariffApplication(tariffApplicationForUpdate)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { tariffApplication } = response.data;
      dispatch(actions.tariffApplicationUpdated({ tariffApplication }));
    })
    .catch(error => {
      error.clientMessage = "Can't update tariff applicaation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const findTariffApplications = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findTariffApplications(params)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { totalCount, entities: tariffApplications } = response.data;
      dispatch(actions.tariffApplicationsFetched({ totalCount, tariffApplications }));
    })
    .catch(error => {
      error.clientMessage = "Can't find tariff Applications";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const sendSTKPush = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .sendSTKPush(params)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { STKResult } = response.data;
      dispatch(actions.STKPushFetched({ STKResult }));
    })
    .catch(error => {
      console.log(error);
      error.clientMessage = "Can't send STK Push";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getPaymentInvoice = licenceApplicationId => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getPaymentInvoice(licenceApplicationId)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { LicenceInvoicing:licenceInvoicing,Invoices:licenseInvoices } = response.data;
      dispatch(actions.paymentInvoicesFetched({ licenceInvoicing,licenseInvoices }));
    })
    .catch(error => {
      console.log(error);
      error.clientMessage = "Can't send STK Push";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createLicenseApplication = licenseApplicationForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createLicenseApplication(licenseApplicationForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { licenseApplication } = response.data;
      dispatch(actions.licenseApplicationCreated({ licenseApplication }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Licence Application";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createLicenseApplications = licenseApplicationsForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createLicenseApplications(licenseApplicationsForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { licenseApplications } = response.data;
      dispatch(actions.licenseApplicationsCreated({ licenseApplications }));
      return licenseApplications;
    })
    .catch(error => {
      error.clientMessage = "Can't create Licence Applications";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createLicencePayment = paymentData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createLicencePayment(paymentData)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { licensePayment } = response.data;
      dispatch(actions.licensePaymentCreated({ licensePayment }));
    })
    .catch(error => {
      error.clientMessage = "Can't create Licence Payment";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const checkMPesaCode = values => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .checkMPesaCode(values)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const codeCheckResult = response.data;
      return codeCheckResult;
    })
    .catch(error => {
      error.clientMessage = "Cannot validate MPesa Code";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateLicenseApplication = licenseApplicationForUpdate => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateLicenseApplication(licenseApplicationForUpdate)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { licenseApplication } = response.data;
      dispatch(actions.licenseApplicationUpdated({ licenseApplication }));
    })
    .catch(error => {
      error.clientMessage = "Can't update license applicaation";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const findLicenseApplication = id => dispatch => {
  if (!id) {
    return dispatch(actions.licenseApplicationFetched({ licenseApplication: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLicenseApplicationById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const {licenseApplication:newLicenseApplication } = response.data;
      dispatch(actions.licenseApplicationFetched({ licenseApplication: newLicenseApplication }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Licence Applications";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const findLicenseApplicationByExpressionOfInterestId = expressionOfInterestId => dispatch => {
  if (!expressionOfInterestId) {
    return dispatch(actions.licenseApplicationFetched({ licenseApplication: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLicenseApplicationByExpressionOfInterestId(expressionOfInterestId)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const {licenseApplication:newLicenseApplication} = response.data;
      dispatch(actions.licenseApplicationFetched({ licenseApplication: newLicenseApplication }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Licence Application";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};



export const findLicenseApplications = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findLicenseApplications(params)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { totalCount, entities: licenseApplications } = response.data;
      dispatch(actions.licenseApplicationsFetched({ totalCount, licenseApplications }));
    })
    .catch(error => {
      error.clientMessage = "Can't find license Applications";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const findmonthlyPerformanceReports = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findMonthlyPerformanceReports(params)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { totalCount, entities: monthlyPerformanceReports } = response.data;
      dispatch(actions.monthlyPerformanceReportsFetched({ totalCount, monthlyPerformanceReports }));
    })
    .catch(error => {
      error.clientMessage = "Can't find monthly reports";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};




export const createExpressionOfInterest = expressionOfInterestForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createExpressionOfInterest(expressionOfInterestForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { expressionOfInterest } = response.data;
      dispatch(actions.expressionOfInterestCreated({ expressionOfInterest }));
    })
    .catch(error => {
      error.clientMessage = "Can't create expression of interest";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createExpressionsOfInterest = expressionsOfInterestForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createExpressionsOfInterest(expressionsOfInterestForCreation)
    .then(response => {
      console.log(response);
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { expressionsOfInterest } = response.data;      
      dispatch(actions.expressionsOfInterestCreated({ expressionsOfInterest }));
      return expressionsOfInterest;
    })
    .catch(error => {
      error.clientMessage = "Can't create expressions of interest";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const findExpressionOfInterest = id => dispatch => {
  if (!id) {
    return dispatch(actions.expressionOfInterestFetched({ expressionOfInterest: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getExpressionOfInterestById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const newExpressionOfInterest = response.data;
      dispatch(actions.expressionOfInterestFetched({ expressionOfInterest: newExpressionOfInterest }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Expression of Interest";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};


export const updateExpressionOfInterest = expressionOfInterestForUpdate => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateExpressionOfInterest(expressionOfInterestForUpdate)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { expressionOfInterest } = response.data;
      dispatch(actions.expressionOfInterestUpdated({ expressionOfInterest }));
    })
    .catch(error => {
      error.clientMessage = "Can't update expression of interest";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const findExpressionsOfInterest = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findExpressionsOfInterest(params)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { totalCount, entities: expressionsOfInterest } = response.data;
      dispatch(actions.expressionsOfInterestFetched({ totalCount, expressionsOfInterest }));
    })
    .catch(error => {
      error.clientMessage = "Can't find expressions of interest";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const updateAllExpressionsOfInterestDependencies = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAllExpressionsOfInterestDependencies(params)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const updateCounts = response.data;
      dispatch(actions.expressionsOfInterestDependenciesUpdated({ updateCounts }));
    })
    .catch(error => {
      error.clientMessage = "Can't update expressions of interest";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const updateAllLicenseStatuses = userId => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateAllLicenseStatuses(userId)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const updateCounts = response.data;
      dispatch(actions.expressionsOfInterestDependenciesUpdated({ updateCounts }));
    })
    .catch(error => {
      error.clientMessage = "Can't update all license statuses";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const findExpressionsOfInterestCount = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findExpressionsOfInterestCount(params)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 2) dispatch(auth.actions.logout());
      const { totalCount } = response.data;
      dispatch(actions.expressionsOfInterestFetchedCount({ totalCount }));
    })
    .catch(error => {
      error.clientMessage = "Can't find expressions of interest";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const getCorporateInformationByUserId = userId => dispatch => {
  if (!userId) {
    return dispatch(actions.corporateInformationFetched({ corporateInformation: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCorporateInformationByUserId(userId)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const corporateInformation = response.data;
      dispatch(actions.corporateInformationFetched({ corporateInformation: corporateInformation }));
    })
    .catch(error => {
      error.clientMessage = "Can't find corporate InformationFetched";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const getMonthlyPerformanceReport = reportId => dispatch => {
  if (!reportId) {
    return dispatch(actions.monthlyPerformanceReportFetched({ corporateInformation: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMonthlyPerformanceReport(reportId)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const monthlyPerformanceReport = response.data;
      dispatch(actions.monthlyPerformanceReportFetched({ monthlyPerformanceReport: monthlyPerformanceReport }));
    })
    .catch(error => {
      error.clientMessage = "Can't find corporate InformationFetched";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const findConstructionPermits = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findConstructionPermits(params)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities: constructionPermits } = response.data;
      dispatch(actions.constructionPermitsFetched({ totalCount, constructionPermits }));
    })
    .catch(error => {
      error.clientMessage = "Can't find construction permits";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const acknowledgeConstructionPermit = params => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .acknowledgeConstructionPermit(params)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities: constructionPermits } = response.data;
      dispatch(actions.constructionPermitsFetched({ totalCount, constructionPermits }));
    })
    .catch(error => {
      error.clientMessage = "Can't acknowledge construction permits";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};


export const uploadFile = formData => dispatch => {
  if (formData === null || formData === undefined) {
    return dispatch(actions.applicationFetched({ fileUploaded: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .upload(formData)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { name: fileName } = response.data;
      dispatch(actions.fileUploaded({ fileUploaded: fileName }));
    })
    .catch(error => {
      error.clientMessage = "Can't upload file";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteFile = deleteParams => dispatch => {
  if (deleteParams === null || deleteParams === undefined) {
    return dispatch(actions.applicationFetched({ fileUploaded: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteFile(deleteParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { name: fileName } = response.data;
      dispatch(actions.fileUploaded({ fileUploaded: fileName }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete file";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteApplication = id => async dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  try {
    const response = await requestFromServer
      .deleteApplication(id);
    if (response.data.result_code === 2 || response.data.result_code === 7)
      dispatch(auth.actions.logout());
    dispatch(actions.applicationDeleted({ id }));
  } catch (error) {
    error.clientMessage = "Can't delete application";
    dispatch(actions.catchError({ error, callType: callTypes.action }));
  }
};

export const createConstructionPermit = permitForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createConstructionPermit(permitForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { constructionPermit } = response.data;
      dispatch(actions.constructionPermitCreated({ constructionPermit }));
    })
    .catch(error => {
      error.clientMessage = "Can't create construction permit";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateConstructionPermit = permitForUpdate => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateConstructionPermit(permitForUpdate)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { constructionPermit } = response.data;
      dispatch(actions.constructionPermitUpdated({ constructionPermit }));
    })
    .catch(error => {
      error.clientMessage = "Can't update construction permit";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createCorporateInformation = corporateInformationForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCorporateInformation(corporateInformationForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { corporateInformation } = response.data;
      dispatch(actions.corporateInformationCreated({ corporateInformation }));
    })
    .catch(error => {
      error.clientMessage = "Can't create corporate information";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};



export const createApplication = applicationForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createApplication(applicationForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { application } = response.data;
      dispatch(actions.applicationCreated({ application }));
    })
    .catch(error => {
      error.clientMessage = "Can't create application";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateApplication = application => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateApplication(application)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.applicationUpdated({ application }));
    })
    .catch(error => {
      error.clientMessage = "Can't update application";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateApplicationsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForApplications(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.applicationsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update applications status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteApplications = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteApplications(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.applicationsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete applications";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchMyApplications = id => dispatch => {
  if (!id) {
    return dispatch(actions.myApplicationsFetched({ totalCount: 0, entities: [] }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMyApplications(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities, individual_applications } = response.data;
      dispatch(actions.myApplicationsFetched({ totalCount, entities, individual_applications }));
    })
    .catch(error => {
      error.clientMessage = "Can't find applications";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
