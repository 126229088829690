import axios from "axios";
import { appConfigs } from "../../../../../_epra/_helpers";

// CREATE =>  POST: add a new business to the server
export function createBusiness(business) {
  return axios.post(appConfigs.api_url + 'businesses/create-business', { business });
}

// READ
export function getAllBusinesses() {
  return axios.get(appConfigs.api_url + 'businesses');
}

export function getBusinessById(businessId) {
  return axios.post(`${appConfigs.api_url}businesses/get-business-by-id`, { business_id: businessId })
}

export function getMyBusinesses(user_id) {
  return axios.post(`${appConfigs.api_url}businesses/get-my-businesses`, { _id: user_id })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findBusinesses(queryParams) {
  return axios.post(`${appConfigs.api_url}businesses/find-businesses`, { queryParams });
}

// UPDATE => POST: update the business on the server
export function updateBusiness(business) {
  return axios.post(`${appConfigs.api_url}businesses/update-business`, { business });
}

// UPDATE Status
export function updateStatusForBusinesses(ids, status) {
  return axios.post(`${appConfigs.api_url}/updateStatusForBusinesses`, {
    ids,
    status
  });
}

// DELETE => delete the business from the server
export function deleteBusiness(businessId) {
  return axios.post(`${appConfigs.api_url}businesses/delete-business`, { business_id: businessId });
}

// DELETE Businesses by ids
export function deleteBusinesses(ids) {
  return axios.post(`${appConfigs.api_url}businesses/delete-businesses`, { business_ids: ids });
}
