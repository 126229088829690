import axios from "axios";
import { appConfigs } from "../../../../../_epra/_helpers";

// CREATE =>  POST: add a new application to the server
export function createApplication(application) {
  return axios.post(appConfigs.api_url + 'applications/create-application', { application });
}



export function createCorporateInformation(corporateInformation) {
  return axios.post(appConfigs.api_url + 'corporate-information/create-corporate-information', { corporateInformation });
}

export function createExpressionOfInterest(expressionOfInterest) {
  return axios.post(appConfigs.api_url + 'expression-of-interest/create-expression-of-interest', { expressionOfInterest });
}
export function createExpressionsOfInterest(expressionsOfInterest) {
  return axios.post(appConfigs.api_url + 'expression-of-interest/create-expressions-of-interest', { expressionsOfInterest });
}

export function updateExpressionOfInterest(expressionOfInterest) {
  return axios.post(appConfigs.api_url + 'expression-of-interest/update-expression-of-interest', { expressionOfInterest });
}
export function findExpressionsOfInterest(queryParams) {
  return axios.post(appConfigs.api_url + 'expression-of-interest/find-expressions-of-interest', { queryParams });
}
export function updateAllExpressionsOfInterestDependencies(queryParams) {
  return axios.post(appConfigs.api_url + 'expression-of-interest/update-dependencies', { queryParams });
}

export function updateAllLicenseStatuses(userId) {
  return axios.post(appConfigs.api_url + 'expression-of-interest/update-statuses', { userId });
}

export function findExpressionsOfInterestCount(queryParams) {
  return axios.post(appConfigs.api_url + 'expression-of-interest/find-expressions-of-interest-count', { queryParams });
}


export function getExpressionOfInterestById(id) {
  return axios.post(`${appConfigs.api_url}expression-of-interest/get-expression-of-interest-by-id`, { expressionOfInterestId: id })
}


export function createTariffApplications(tariffApplications) {
  return axios.post(appConfigs.api_url + 'tariff-application/create-tariff-applications', { tariffApplications });
}
export function updateTariffApplicationsByEOI(tariffApplications) {
  return axios.post(appConfigs.api_url + 'tariff-application/update-tariff-applications-by-eoi', { tariffApplications });
}


export function createTariffApplication(tariffApplication) {
  return axios.post(appConfigs.api_url + 'tariff-application/create-tariff-application', { tariffApplication });
}
export function updateTariffApplication(tariffApplication) {
  return axios.post(appConfigs.api_url + 'tariff-application/update-tariff-application', { tariffApplication });
}
export function findTariffApplications(queryParams) {
  return axios.post(appConfigs.api_url + 'tariff-application/find-tariff-application', { queryParams });
}

export function getTariffApplicationById(id) {
  return axios.post(`${appConfigs.api_url}tariff-application/get-tariff-application-by-id`, { tariffApplicationId: id })
}

export function updateTariffApplicationModelById(id) {
  return axios.post(`${appConfigs.api_url}tariff-application/update-tariff-application-model-by-id`, { tariffApplicationId: id })
}
export function updateTariffApplicationModelByExpressionOfInterestId(id) {
  return axios.post(`${appConfigs.api_url}tariff-application/update-tariff-application-model-by-expression-of-interest-id`, { expressionOfInterestId: id })
}
export function getTariffApplicationByConstructionPermitId(constructionPermitId) {
  return axios.post(`${appConfigs.api_url}tariff-application/get-tariff-application-by-construction-permit-id`, { constructionPermitId: constructionPermitId })
}

export function getTariffApplicationByExpressionOfInterestId(expressionOfInterestId) {
  return axios.post(`${appConfigs.api_url}tariff-application/get-tariff-application-by-expression-of-interest-id`, { expressionOfInterestId: expressionOfInterestId })
}



export function createLicenseApplication(licenseApplication) {
  return axios.post(appConfigs.api_url + 'license-application/create-license-application', { licenseApplication });
}
export function sendSTKPush(params) {
  return axios.post(appConfigs.api_url + 'license-application/send-stk-push', { pushValues:params });
}
export function getPaymentInvoice(LicenceApplicationID) {
  return axios.post(appConfigs.api_url + 'license-application/get-payment-invoice', { LicenceApplicationID });
}
export function createLicencePayment(licensePayment) {
  return axios.post(appConfigs.api_url + 'license-application/create-license-payment', { licensePayment });
}
export function checkMPesaCode(values) {
  return axios.post(appConfigs.api_url + 'license-application/check-mpesa-code', { values });
}
export function createLicenseApplications(licenseApplications) {
  return axios.post(appConfigs.api_url + 'license-application/create-license-applications', { licenseApplications });
}
export function updateLicenseApplication(licenseApplication) {
  return axios.post(appConfigs.api_url + 'license-application/update-license-application', { licenseApplication });
}
export function findLicenseApplications(queryParams) {
  return axios.post(appConfigs.api_url + 'license-application/find-license-application', { queryParams });
}

export function findMonthlyPerformanceReports(queryParams) {
  return axios.post(appConfigs.api_url + 'monthly-performance-reports/find-monthly-performance-reports', { queryParams });
}

export function getMonthlyPerformanceReport(reportId) {
  return axios.post(appConfigs.api_url + 'monthly-performance-reports/get-monthly-performance-report-by-id', { reportId });
}


export function getLicenseApplicationByExpressionOfInterestId(expressionOfInterestId) {
  return axios.post(`${appConfigs.api_url}license-application/get-license-application-by-expression-of-interest-id`, { expressionOfInterestId: expressionOfInterestId })
}

export function getLicenseApplicationById(id) {
  return axios.post(`${appConfigs.api_url}license-application/get-license-application-by-id`, { licenseApplicationId: id })
}


export function createConstructionPermit(constructionPermit) {
  return axios.post(appConfigs.api_url + 'construction-permits/create-construction-permit', { constructionPermit });
}
export function updateConstructionPermit(constructionPermit) {
  return axios.post(appConfigs.api_url + 'construction-permits/update-construction-permit', { constructionPermit });
}
export function findConstructionPermits(queryParams) {
  return axios.post(appConfigs.api_url + 'construction-permits/find-construction-permits', { queryParams });
}

export function acknowledgeTariffApplication(tariffApplicationReview) {
  return axios.post(appConfigs.api_url + 'tariff-application/create-tariff-application-acknowledgement', { tariffApplicationReview });
}
export function approveTariffApplication(tariffApplicationReview) {
  return axios.post(appConfigs.api_url + 'tariff-application/create-tariff-application-review', { tariffApplicationReview });
}
export function voteTariffApplication(tariffApplicationReview) {
  return axios.post(appConfigs.api_url + 'tariff-application/vote-tariff-application-review', { tariffApplicationReview });
}
export function committeeVoteTariffApplication(tariffApplicationReview) {
  return axios.post(appConfigs.api_url + 'tariff-application/create-committee-tariff-application-review', { tariffApplicationReview });
}
export function directorApproveTariffApplication(tariffApplicationReview) {
  return axios.post(appConfigs.api_url + 'tariff-application/create-director-tariff-application-review', { tariffApplicationReview });
}

export function acknowledgeLicenseApplication(licenseApplicationReview) {
  return axios.post(appConfigs.api_url + 'license-application/create-license-application-acknowledgement', { licenseApplicationReview });
}
export function approveLicenseApplication(licenseApplicationReview) {
  return axios.post(appConfigs.api_url + 'license-application/create-license-application-review', { licenseApplicationReview });
}
export function voteLicenseApplication(licenseApplicationReview) {
  return axios.post(appConfigs.api_url + 'license-application/vote-license-application-review', { licenseApplicationReview });
}
export function committeeVoteLicenseApplication(licenseApplicationReview) {
  return axios.post(appConfigs.api_url + 'license-application/create-committee-license-application-review', { licenseApplicationReview });
}
export function directorApproveLicenseApplication(licenseApplicationReview) {
  return axios.post(appConfigs.api_url + 'license-application/create-director-license-application-review', { licenseApplicationReview });
}

export function approveConstructionPermit(constructionPermitReview) {
  return axios.post(appConfigs.api_url + 'construction-permits/create-construction-permit-review', { constructionPermitReview: constructionPermitReview });
}

export function addMonthlyReport(monthlyReport) {
  return axios.post(appConfigs.api_url + 'monthly-performance-reports/create-monthly-performance-report', { monthlyReport: monthlyReport });
}

export function findMonthlyPerformanceReport(params) {
  return axios.post(appConfigs.api_url + 'monthly-performance-reports/find-monthly-performance-report', { params: params });
}


export function acknowledgeExpressionOfInterest(expressionOfInterestReview) {
  return axios.post(appConfigs.api_url + 'expression-of-interest/create-expression-of-interest-acknowledgement', { expressionOfInterestReview: expressionOfInterestReview });
}

export function approveExpressionOfInterest(expressionOfInterestReview) {
  return axios.post(appConfigs.api_url + 'expression-of-interest/create-expression-of-interest-review', { expressionOfInterestReview: expressionOfInterestReview });
}
export function voteExpressionOfInterest(expressionOfInterestReview) {
  return axios.post(appConfigs.api_url + 'expression-of-interest/vote-expression-of-interest-review', { expressionOfInterestReview: expressionOfInterestReview });
}
export function committeeVoteExpressionOfInterest(expressionOfInterestReview) {
  return axios.post(appConfigs.api_url + 'expression-of-interest/create-committee-expression-of-interest-review', { expressionOfInterestReview: expressionOfInterestReview });
}
export function directorApproveExpressionOfInterest(expressionOfInterestReview) {
  return axios.post(appConfigs.api_url + 'expression-of-interest/create-director-expression-of-interest-review', { expressionOfInterestReview: expressionOfInterestReview });
}


export function fetchExpressionOfInterestReviews(constructionPermitId) {
  return axios.post(appConfigs.api_url + 'expression-of-interest/expression-of-interest-reviews-by-construction-permit-id', { constructionPermitId: constructionPermitId });
}
export function fetchConstructionPermitReviews(constructionPermitId) {
  return axios.post(appConfigs.api_url + 'construction-permits/construction-permit-reviews-by-construction-permit-id', { constructionPermitId: constructionPermitId });
}

export function acknowledgeConstructionPermit(queryParams) {
  return axios.post(appConfigs.api_url + 'construction-permits/acknowledge-construction-permits', { queryParams });
}

export function getconstructionPermitById(id) {
  return axios.post(`${appConfigs.api_url}construction-permits/get-construction-permit-by-id`, { constructionPermitId: id })
}
export function getconstructionPermitByUserId(userId) {
  return axios.post(`${appConfigs.api_url}construction-permits/get-construction-permit-by-user-id`, { userId: userId })
}
export function getconstructionPermitByEOI(expressionOfInterestId) {
  return axios.post(`${appConfigs.api_url}construction-permits/get-construction-permit-by-eoi-id`, { expressionOfInterestId: expressionOfInterestId })
}



export function getCorporateInformationByUserId(id) {
  return axios.post(`${appConfigs.api_url}corporate-information/get-corporate-information-by-user-id`, { userId: id })
}

export function upload(formData) {
  //return axios.post(appConfigs.api_url + 'applications/create-application', { application });
  return axios.post(appConfigs.api_url + 'construction-permits/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export function deleteFile(params) {
  return axios.post(appConfigs.api_url + params.path + '/delete-file', {file: params});
}
// READ
export function getAllApplications() {
  return axios.get(appConfigs.api_url + 'applications');
}

export function getApplicationById(applicationId) {
  return axios.post(`${appConfigs.api_url}applications/get-application-by-id`, { application_id: applicationId })
}



export function getMyApplications(user_id) {
  return axios.post(`${appConfigs.api_url}applications/get-my-applications`, { _id: user_id })
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findApplications(queryParams) {
  return axios.post(`${appConfigs.api_url}applications/find-applications`, { queryParams });
}

// UPDATE => POST: update the application on the server
export function updateApplication(application) {
  return axios.post(`${appConfigs.api_url}applications/update-application`, { application });
}

// UPDATE Status
export function updateStatusForApplications(ids, status) {
  return axios.post(`${appConfigs.api_url}/updateStatusForApplications`, {
    ids,
    status
  });
}

// DELETE => delete the application from the server
export function deleteApplication(applicationId) {
  return axios.post(`${appConfigs.api_url}applications/delete-application`, { application_id: applicationId });
}

// DELETE Applications by ids
export function deleteApplications(ids) {
  return axios.post(`${appConfigs.api_url}applications/delete-applications`, { application_ids: ids });
}
