import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const [loading, setLoading] = useState(false);
  const alert = props.history.location.state?.alert;
  const [resend, setResend] = useState(false);
  const { intl } = props;
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({id: "AUTH.VALIDATION.INVALID",}, {name: 'Email'}))
      .min(5, intl.formatMessage({id: "AUTH.VALIDATION.MIN_LENGTH",}, {name: 'Email', min: '5'}))
      .max(250, intl.formatMessage({id: "AUTH.VALIDATION.MAX_LENGTH",}, {name: 'Email', max: '250'}))
      .required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED",}, {name: 'Email'})),
    password: Yup.string()
      // .min(6, intl.formatMessage({id: "AUTH.VALIDATION.MIN_LENGTH",}, {name: 'Password', min: '6'}))
      .max(50, intl.formatMessage({id: "AUTH.VALIDATION.MAX_LENGTH",}, {name: 'Password', max: '50'}))
      .required(intl.formatMessage({id: "AUTH.VALIDATION.REQUIRED",}, {name: 'Password'})),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const enableResend = () => {
    setResend(true);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {

      var navigateTo = '';
      var navigateAlert = {};

      enableLoading();
      login(values.email, values.password)
        .then((resp) => {
          // console.log(resp.data);
          if(resp.data.result_code === 1) {
            setStatus({msg: intl.formatMessage({id: "AUTH.LOGIN.SUCCESS",}, {name: resp.data.user.firstname}), type: 'success'});
            props.login(resp.data.token);
            // navigateTo = "/auth/business-options";
          } else if(resp.data.result_code === 0) {
            setStatus({msg: intl.formatMessage({id: "AUTH.VALIDATION.INVALID_LOGIN",}), type: 'danger'});
          } else if(resp.data.result_code === 3) {
            // navigateAlert = {msg: intl.formatMessage({id: "AUTH.LOGIN.NOT_VERIFIED",}, {email: resp.data.data.email}), type: 'info'};
            // navigateTo = "/auth/verify-email";
            enableResend();
            setStatus({msg: intl.formatMessage({id: "AUTH.LOGIN.NOT_VERIFIED",}, {email: resp.data.data.email}), type: 'danger'});
          } else {
            setStatus({msg: intl.formatMessage({id: "AUTH.VALIDATION.INVALID_LOGIN",}), type: 'danger'});
          }
        })
        .catch(() => {
          disableLoading();
          setSubmitting(false);
          setStatus({msg: intl.formatMessage({id: "AUTH.VALIDATION.INVALID_LOGIN",}), type: 'danger'});
        }).finally(() => {
          disableLoading();
          setSubmitting(false);
          if (navigateTo !== '') props.history.replace(navigateTo, {alert: navigateAlert});
        });
    },
  });

  const resendVerificationEmail = (_resend) => {
    return _resend ? <span>
      &nbsp;
      <span className="text-primary font-weight-bold">
        <FormattedMessage id="AUTH.VERIFY.EMAIL.RESEND" />
      </span>
      <Link to="/auth/verify-email-request" className="text-primary font-weight-boldest text-hover-info ml-2" id="kt_login_forgot" >
        <FormattedMessage id="AUTH.GENERAL.RESEND" />
      </Link>
    </span> : '';
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1"><FormattedMessage id="AUTH.LOGIN.TITLE" /></h3>
        <p className="text-muted font-weight-bold"><FormattedMessage id="AUTH.LOGIN.DESCR" /></p>
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className={`mb-10 alert alert-custom alert-light-${formik.status.type} alert-dismissible`}>
            <div className="alert-text font-weight-bold">
              {formik.status.msg}
              {resendVerificationEmail(resend)}
            </div>
          </div>
        ) : alert ? (
          <div className={`mb-10 alert alert-custom alert-light-${alert.type} alert-dismissible`}>
            <div className="alert-text font-weight-bold">{alert.msg}</div>
          </div>
        ) : null}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Your email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            autoComplete="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Your password"
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            autoComplete="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          {/* <Link to="/auth/signup">
            <button
              type="button"
              className="btn btn-light-primary font-weight-bold px-9 py-4 mb-3"
            >
              <FormattedMessage id="AUTH.GENERAL.NO_ACCOUNT" />
            </button>
          </Link> */}
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className={`btn btn-primary btn-block font-weight-bold px-9 py-4 mb-3- ml-2-`}
          >
            <span><FormattedMessage id="AUTH.GENERAL.CONTINUE_BUTTON" /></span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <Link to="/auth/forgot-password" className="text-dark-50 font-weight-bold text-hover-primary my- ml-2" id="kt_login_forgot">
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link>
          <Link to="/auth/signup" className="text-dark-50 font-weight-boldest text-hover-primary my- ml-2" id="kt_login_forgot">
            <FormattedMessage id="AUTH.GENERAL.NO_ACCOUNT" />
          </Link>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
