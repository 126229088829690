import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
//import {LayoutSplashScreen} from "../../../../_epra/layout";
import * as auth from "../_redux/authRedux";
import { logout } from "../_redux/authCrud";

class Logout extends Component {
  componentDidMount() {
    this.props.logout();
  }
  
  render() {
    logout();
    var x = 1;
    //const { hasAuthToken } = this.props;
    console.log('Ready to logout');  
    //return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="https://portal.erc.go.ke:8443/site/logout" />;
    //return <Redirect to="https://portal.erc.go.ke:8443/site/logout" />;
    return x === 0? <Redirect to="https://portal.erc.go.ke:8443/site/logout" /> : window.location = "https://portal.erc.go.ke:8443/site/logout";
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken) }),
  auth.actions
)(Logout);
