import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import "leaflet/dist/leaflet.css";
import { Card } from 'react-bootstrap';
import { shallowEqual, useSelector } from "react-redux";

export function Maps(){
    React.useEffect(() => {
      const L = require("leaflet");
  
      delete L.Icon.Default.prototype._getIconUrl;
  
      L.Icon.Default.mergeOptions({
        iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
        iconUrl: require("leaflet/dist/images/marker-icon.png"),
        shadowUrl: require("leaflet/dist/images/marker-shadow.png")
      });
    }, []);

    const { currentState } = useSelector(
      (state) => ({ currentState: state.applications }),
      shallowEqual
    );
  
    const { expressionsOfInterest } = currentState;
  
    return (
        <Card variant="primary" className="mt-4">
            <Card.Body>
                <Card.Title>Below are your site Locations</Card.Title>
                  <MapContainer center={[0.3689, 35.2863]} zoom={7} style={{ height: "100vh",border: "2px solid #223388" }}>
                      <TileLayer
                      attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      {expressionsOfInterest&&expressionsOfInterest.length>0&&expressionsOfInterest.map((site,index)=>{
                        return (
                          <Marker key={index} position={[(!isNaN(site.location[0].lat)?site.location[0].lat:0.3689),(!isNaN(site.location[0].long)?site.location[0].long:35.2863)]}>
                            <Popup>
                                <strong>{site.siteName}</strong>
                            </Popup>
                        </Marker>
                        );
                        })}
                      
                      
           
                  </MapContainer>
            </Card.Body>
      </Card>

        
      
      
    );
  };