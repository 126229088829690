export const appConfigs = {
    app_name: "EPRA Mini-Grid Licensing Portal",
    org_name: process.env.NODE_ENV !== "production" ? "EPRA (Dev)" : "EPRA",
    org_url: "https://epra.go.ke",
    api_url: process.env.NODE_ENV !== "production" ? "http://localhost:8187/api/" : (window.location.host === "epra-rmis.sandbox.co.ke" ? "https://epra-rmis-api.sandbox.co.ke:8547/api/" : "https://portal.erc.go.ke:8547/api/"),
    //portal_api_url: "https://portal.erc.go.ke:5597/api/",
    portal_api_url: "https://portal.erc.go.ke:8443/api/",
    
    auth_key: process.env.NODE_ENV !== "production" ? "XNAgZSnoEsf55nlbmDIJ" : "rK32640byHUTixKE7ge1",
    uploads_url: process.env.NODE_ENV !== "production" ? "http://localhost:8187/u0veDcqIsErQVKXuXeM3/" : (window.location.host === "epra-rmis.sandbox.co.ke" ? "https://epra-rmis-api.sandbox.co.ke:8547/u0veDcqIsErQVKXuXeM3/" : "https://portal.erc.go.ke:8547/u0veDcqIsErQVKXuXeM3/"),
    recaptcha_site_key: "6LfdYeQZAAAAAPijoDtW-p4RLdUZBnc0brmarW5M",
};