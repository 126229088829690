import * as requestFromServer from "./businessesCrud";
import { businessesSlice, callTypes } from "./businessesSlice";
import * as auth from "../../../Auth/_redux/authRedux";

const { actions } = businessesSlice;

export const fetchBusinesses = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findBusinesses(queryParams)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities } = response.data;
      dispatch(actions.businessesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find businesses";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchBusiness = id => dispatch => {
  if (!id) {
    return dispatch(actions.businessFetched({ businessForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getBusinessById(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const business = response.data;
      dispatch(actions.businessFetched({ businessForEdit: business }));
    })
    .catch(error => {
      error.clientMessage = "Can't find business";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteBusiness = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteBusiness(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.businessDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete business";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createBusiness = businessForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createBusiness(businessForCreation)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { business } = response.data;
      dispatch(actions.businessCreated({ business }));
    })
    .catch(error => {
      error.clientMessage = "Can't create business";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateBusiness = business => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateBusiness(business)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.businessUpdated({ business }));
    })
    .catch(error => {
      error.clientMessage = "Can't update business";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateBusinessesStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForBusinesses(ids, status)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.businessesStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update businesses status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteBusinesses = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteBusinesses(ids)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      dispatch(actions.businessesDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete businesses";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchMyBusinesses = id => dispatch => {
  if (!id) {
    return dispatch(actions.myBusinessesFetched({ totalCount: 0, entities: [] }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getMyBusinesses(id)
    .then(response => {
      if (response.data.result_code === 2 || response.data.result_code === 7) dispatch(auth.actions.logout());
      const { totalCount, entities, individual_applications } = response.data;
      dispatch(actions.myBusinessesFetched({ totalCount, entities, individual_applications }));
    })
    .catch(error => {
      error.clientMessage = "Can't find businesses";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};
