import React, { useEffect } from "react";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import * as actions from "../modules/Licensing/_redux/applications/applicationsActions";
import { Alert } from "react-bootstrap";
import { Maps } from "../widgets";
import { SitesPage } from "../modules/Sites/SitesPage";
import { ApplicationProcess } from "../../_epra/layout/components/extras/ApplicationProcess";
import { Link } from "react-router-dom";

export function DashboardPage() {
    const { user_id } = useSelector(
        (state) => ({
            user_id: state.auth.user.id || "",
        }),
        shallowEqual
    );


    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.findExpressionsOfInterestCount({
            filter: {
                userId: user_id,
            }
        }));
        // eslint-disable-next-line 
    }, [dispatch, user_id]);

    const { currentState } = useSelector(
        (state) => ({ currentState: state.applications }),
        shallowEqual
    );
    const { expressionsOfInterestTotalCount } = currentState;


    return (
        <>
            {expressionsOfInterestTotalCount === 0 && <ApplicationProcess />}
            {expressionsOfInterestTotalCount === 0 && <Alert variant="light">
                <h3 className="text-center">Welcome! Submit Your Application</h3>
                <p className="text-center">To add your application use the link below:</p>
                <p className="text-center p-4"><Link to="/licensing/applications/my-expressions-of-interest" className="btn btn-lg btn-primary">Create New</Link></p>
            </Alert>}
            {expressionsOfInterestTotalCount > 0 && <SitesPage />}
            {expressionsOfInterestTotalCount > 0 && <Maps />}
        </>
    );
}
