export const countyOptions = [
	{
		code: 30,
		name: "Baringo"
	},
	{
		code: 36,
		name: "Bomet"
	},
	{
		code: 39,
		name: "Bungoma"
	},
	{
		code: 40,
		name: "Busia"
	},
	{
		code: 28,
		name: "Elgeyo-Marakwet"
	},
	{
		code: 14,
		name: "Embu"
	},
	{
		code: 7,
		name: "Garissa"
	},
	{
		code: 43,
		name: "Homa Bay"
	},
	{
		code: 11,
		name: "Isiolo"
	},
	{
		code: 34,
		name: "Kajiado"
	},
	{
		code: 37,
		name: "Kakamega"
	},
	{
		code: 35,
		name: "Kericho"
	},
	{
		code: 22,
		name: "Kiambu"
	},
	{
		code: 3,
		name: "Kilifi"
	},
	{
		code: 20,
		name: "Kirinyaga"
	},
	{
		code: 45,
		name: "Kisii"
	},
	{
		code: 42,
		name: "Kisumu"
	},
	{
		code: 15,
		name: "Kitui"
	},
	{
		code: 2,
		name: "Kwale"
	},
	{
		code: 31,
		name: "Laikipia"
	},
	{
		code: 5,
		name: "Lamu"
	},
	{
		code: 16,
		name: "Machakos"
	},
	{
		code: 17,
		name: "Makueni"
	},
	{
		code: 9,
		name: "Mandera"
	},
	{
		code: 10,
		name: "Marsabit"
	},
	{
		code: 12,
		name: "Meru"
	},
	{
		code: 44,
		name: "Migori"
	},
	{
		code: 1,
		name: "Mombasa (County)"
	},
	{
		code: 21,
		name: "Murang'a"
	},
	{
		code: 47,
		name: "Nairobi (County)"
	},
	{
		code: 32,
		name: "Nakuru"
	},
	{
		code: 29,
		name: "Nandi"
	},
	{
		code: 33,
		name: "Narok"
	},
	{
		code: 46,
		name: "Nyamira"
	},
	{
		code: 18,
		name: "Nyandarua"
	},
	{
		code: 19,
		name: "Nyeri"
	},
	{
		code: 25,
		name: "Samburu"
	},
	{
		code: 41,
		name: "Siaya"
	},
	{
		code: 6,
		name: "Taita–Taveta"
	},
	{
		code: 4,
		name: "Tana River"
	},
	{
		code: 13,
		name: "Tharaka-Nithi"
	},
	{
		code: 26,
		name: "Trans-Nzoia"
	},
	{
		code: 23,
		name: "Turkana"
	},
	{
		code: 27,
		name: "Uasin Gishu"
	},
	{
		code: 38,
		name: "Vihiga"
	},
	{
		code: 8,
		name: "Wajir"
	},
	{
		code: 24,
		name: "West Pokot"
	}
];

export const technologyOptions = [
    { id: "1", isOther: 0, name:"Bagasse"}, 
    { id: "2", isOther: 0, name:"Battery"},
    { id: "3", isOther: 0, name:"Biogas"},
    { id: "4", isOther: 0, name:"Biomass"},
    { id: "5", isOther: 0, name:"Diesel generator"},
    { id: "6", isOther: 0, name:"Other thermal generator"},
    { id: "7", isOther: 0, name:"Geothermal"},
    { id: "8", isOther: 0, name:"Hydro (storage)"}, 
    { id: "9", isOther: 0, name:"Hydro (no storage)"}, 
    { id: "10", isOther: 0, name:"Main grid"}, 
    { id: "11", isOther: 0, name:"Solar"},
    { id: "12", isOther: 0, name:"Wind"},
    { id: "13", isOther: 0, name:"Other (please describe)"},

];

export const storageTechnologyOptions = [
    { id: "2", isOther: 0, name:"Battery"},
    { id: "13", isOther: 0, name:"Other (please describe)"},

];

export const customerTypeOptions = [
    { id: "Residential", name:"Residential"}, 
    { id: "Commercial", name:"Commercial"},
    { id: "Community", name:"Community"},
    { id: "Industrial", name:"Industrial"},
    { id: "Anchor", name:"Anchor"},
];

export const monthOptions = [
    {"id":"1","name":"Jan"},
    {"id":"2","name":"Feb"},
    {"id":"3","name":"Mar"},
    {"id":"4","name":"Apr"},
    {"id":"5","name":"May"},
    {"id":"6","name":"Jun"},
    {"id":"7","name":"Jul"},
    {"id":"8","name":"Aug"},
    {"id":"9","name":"Sep"},
    {"id":"10","name":"Oct"},
    {"id":"11","name":"Nov"},
    {"id":"12","name":"Dec"},
];

export const businessTypeOptions = [ 
    {"id":"1","name":"Public Limited Company"},
    {"id":"2","name":"Private Limited Company"},
    {"id":"3","name":"Overseas Company"},
    {"id":"4","name":"Unincorporated  association"},
    {"id":"5","name":"Sole Trader"},
    {"id":"6","name":"Co-operative"},
    {"id":"7","name":"Partnership"},
    {"id":"8","name":"Representative Offices of foreign company"},
    {"id":"9","name":"Society"},
    {"id":"10","name":"Government Agency"},
    {"id":"11","name":"Trust"},
    {"id":"12","name":"CBO"},
    {"id":"13","name":"NGO"}
];

export const countryOptions = [
    {"id":1,"name":"AFGHANISTAN"},
    {"id":2,"name":"ÅLAND ISLANDS"},
    {"id":3,"name":"ALBANIA"},
    {"id":4,"name":"ALGERIA"},
    {"id":5,"name":"AMERICAN SAMOA"},
    {"id":6,"name":"ANDORRA"},
    {"id":7,"name":"ANGOLA"},
    {"id":8,"name":"ANGUILLA"},
    {"id":9,"name":"ANTARCTICA"},
    {"id":10,"name":"ANTIGUA AND BARBUDA"},
    {"id":11,"name":"ARGENTINA"},
    {"id":12,"name":"ARMENIA"},
    {"id":13,"name":"ARUBA"},
    {"id":14,"name":"AUSTRALIA"},
    {"id":15,"name":"AUSTRIA"},
    {"id":16,"name":"AZERBAIJAN"},
    {"id":17,"name":"BAHAMAS"},
    {"id":18,"name":"BAHRAIN"},
    {"id":19,"name":"BANGLADESH"},
    {"id":20,"name":"BARBADOS"},
    {"id":21,"name":"BELARUS"},
    {"id":22,"name":"BELGIUM"},
    {"id":23,"name":"BELIZE"},
    {"id":24,"name":"BENIN"},
    {"id":25,"name":"BERMUDA"},
    {"id":26,"name":"BHUTAN"},
    {"id":27,"name":"BOLIVIA, PLURINATIONAL STATE OF"},
    {"id":28,"name":"BONAIRE, SAINT EUSTATIUS AND SABA"},
    {"id":29,"name":"BOSNIA AND HERZEGOVINA"},
    {"id":30,"name":"BOTSWANA"},
    {"id":31,"name":"BOUVET ISLAND"},
    {"id":32,"name":"BRAZIL"},
    {"id":33,"name":"BRITISH INDIAN OCEAN TERRITORY"},
    {"id":34,"name":"BRUNEI DARUSSALAM"},
    {"id":35,"name":"BULGARIA"},
    {"id":36,"name":"BURKINA FASO"},
    {"id":37,"name":"BURUNDI"},
    {"id":38,"name":"CAMBODIA"},
    {"id":39,"name":"CAMEROON"},
    {"id":40,"name":"CANADA"},
    {"id":41,"name":"CAPE VERDE"},
    {"id":42,"name":"CAYMAN ISLANDS"},
    {"id":43,"name":"CENTRAL AFRICAN REPUBLIC"},
    {"id":44,"name":"CHAD"},
    {"id":45,"name":"CHILE"},
    {"id":46,"name":"CHINA"},
    {"id":47,"name":"CHRISTMAS ISLAND"},
    {"id":48,"name":"COCOS (KEELING) ISLANDS"},
    {"id":49,"name":"COLOMBIA"},
    {"id":50,"name":"COMOROS"},
    {"id":51,"name":"CONGO"},
    {"id":52,"name":"CONGO, THE DEMOCRATIC REPUBLIC OF THE"},
    {"id":53,"name":"COOK ISLANDS"},
    {"id":54,"name":"COSTA RICA"},
    {"id":55,"name":"CÔTE D'IVOIRE"},
    {"id":56,"name":"CROATIA"},
    {"id":57,"name":"CUBA"},
    {"id":58,"name":"CURAÇAO"},
    {"id":59,"name":"CYPRUS"},
    {"id":60,"name":"CZECH REPUBLIC"},
    {"id":61,"name":"DENMARK"},
    {"id":62,"name":"DJIBOUTI"},
    {"id":63,"name":"DOMINICA"},
    {"id":64,"name":"DOMINICAN REPUBLIC"},
    {"id":65,"name":"ECUADOR"},
    {"id":66,"name":"EGYPT"},
    {"id":67,"name":"EL SALVADOR"},
    {"id":68,"name":"EQUATORIAL GUINEA"},
    {"id":69,"name":"ERITREA"},
    {"id":70,"name":"ESTONIA"},
    {"id":71,"name":"ETHIOPIA"},
    {"id":72,"name":"FALKLAND ISLANDS (MALVINAS)"},
    {"id":73,"name":"FAROE ISLANDS"},
    {"id":74,"name":"FIJI"},
    {"id":75,"name":"FINLAND"},
    {"id":76,"name":"FRANCE"},
    {"id":77,"name":"FRENCH GUIANA"},
    {"id":78,"name":"FRENCH POLYNESIA"},
    {"id":79,"name":"FRENCH SOUTHERN TERRITORIES"},
    {"id":80,"name":"GABON"},
    {"id":81,"name":"GAMBIA"},
    {"id":82,"name":"GEORGIA"},
    {"id":83,"name":"GERMANY"},
    {"id":84,"name":"GHANA"},
    {"id":85,"name":"GIBRALTAR"},
    {"id":86,"name":"GREECE"},
    {"id":87,"name":"GREENLAND"},
    {"id":88,"name":"GRENADA"},
    {"id":89,"name":"GUADELOUPE"},
    {"id":90,"name":"GUAM"},
    {"id":91,"name":"GUATEMALA"},
    {"id":92,"name":"GUERNSEY"},
    {"id":93,"name":"GUINEA"},
    {"id":94,"name":"GUINEA-BISSAU"},
    {"id":95,"name":"GUYANA"},
    {"id":96,"name":"HAITI"},
    {"id":97,"name":"HEARD ISLAND AND MCDONALD ISLANDS"},
    {"id":98,"name":"HOLY SEE (VATICAN CITY STATE)"},
    {"id":99,"name":"HONDURAS"},
    {"id":100,"name":"HONG KONG"},
    {"id":101,"name":"HUNGARY"},
    {"id":102,"name":"ICELAND"},
    {"id":103,"name":"INDIA"},
    {"id":104,"name":"INDONESIA"},
    {"id":105,"name":"IRAN, ISLAMIC REPUBLIC OF"},
    {"id":106,"name":"IRAQ"},
    {"id":107,"name":"IRELAND"},
    {"id":108,"name":"ISLE OF MAN"},
    {"id":109,"name":"ISRAEL"},
    {"id":110,"name":"ITALY"},
    {"id":111,"name":"JAMAICA"},
    {"id":112,"name":"JAPAN"},
    {"id":113,"name":"JERSEY"},
    {"id":114,"name":"JORDAN"},
    {"id":115,"name":"KAZAKHSTAN"},
    {"id":116,"name":"KENYA"},
    {"id":117,"name":"KIRIBATI"},
    {"id":118,"name":"KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF"},
    {"id":119,"name":"KOREA, REPUBLIC OF"},
    {"id":120,"name":"KUWAIT"},
    {"id":121,"name":"KYRGYZSTAN"},
    {"id":122,"name":"LAO PEOPLE'S DEMOCRATIC REPUBLIC"},
    {"id":123,"name":"LATVIA"},
    {"id":124,"name":"LEBANON"},
    {"id":125,"name":"LESOTHO"},
    {"id":126,"name":"LIBERIA"},
    {"id":127,"name":"LIBYAN ARAB JAMAHIRIYA"},
    {"id":128,"name":"LIECHTENSTEIN"},
    {"id":129,"name":"LITHUANIA"},
    {"id":130,"name":"LUXEMBOURG"},
    {"id":131,"name":"MACAO"},
    {"id":132,"name":"MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF"},
    {"id":133,"name":"MADAGASCAR"},
    {"id":134,"name":"MALAWI"},
    {"id":135,"name":"MALAYSIA"},
    {"id":136,"name":"MALDIVES"},
    {"id":137,"name":"MALI"},
    {"id":138,"name":"MALTA"},
    {"id":139,"name":"MARSHALL ISLANDS"},
    {"id":140,"name":"MARTINIQUE"},
    {"id":141,"name":"MAURITANIA"},
    {"id":142,"name":"MAURITIUS"},
    {"id":143,"name":"MAYOTTE"},
    {"id":144,"name":"MEXICO"},
    {"id":145,"name":"MICRONESIA, FEDERATED STATES OF"},
    {"id":146,"name":"MOLDOVA, REPUBLIC OF"},
    {"id":147,"name":"MONACO"},
    {"id":148,"name":"MONGOLIA"},
    {"id":149,"name":"MONTENEGRO"},
    {"id":150,"name":"MONTSERRAT"},
    {"id":151,"name":"MOROCCO"},
    {"id":152,"name":"MOZAMBIQUE"},
    {"id":153,"name":"MYANMAR"},
    {"id":154,"name":"NAMIBIA"},
    {"id":155,"name":"NAURU"},
    {"id":156,"name":"NEPAL"},
    {"id":157,"name":"NETHERLANDS"},
    {"id":158,"name":"NEW CALEDONIA"},
    {"id":159,"name":"NEW ZEALAND"},
    {"id":160,"name":"NICARAGUA"},
    {"id":161,"name":"NIGER"},
    {"id":162,"name":"NIGERIA"},
    {"id":163,"name":"NIUE"},
    {"id":164,"name":"NORFOLK ISLAND"},
    {"id":165,"name":"NORTHERN MARIANA ISLANDS"},
    {"id":166,"name":"NORWAY"},
    {"id":167,"name":"OMAN"},
    {"id":168,"name":"PAKISTAN"},
    {"id":169,"name":"PALAU"},
    {"id":170,"name":"PALESTINIAN TERRITORY, OCCUPIED"},
    {"id":171,"name":"PANAMA"},
    {"id":172,"name":"PAPUA NEW GUINEA"},
    {"id":173,"name":"PARAGUAY"},
    {"id":174,"name":"PERU"},
    {"id":175,"name":"PHILIPPINES"},
    {"id":176,"name":"PITCAIRN"},
    {"id":177,"name":"POLAND"},
    {"id":178,"name":"PORTUGAL"},
    {"id":179,"name":"PUERTO RICO"},
    {"id":180,"name":"QATAR"},
    {"id":181,"name":"RÉUNION"},
    {"id":182,"name":"ROMANIA"},
    {"id":183,"name":"RUSSIAN FEDERATION"},
    {"id":184,"name":"RWANDA"},
    {"id":185,"name":"SAINT BARTHÉLEMY"},
    {"id":186,"name":"SAINT HELENA, ASCENSION AND TRISTAN DA CUNHA"},
    {"id":187,"name":"SAINT KITTS AND NEVIS"},
    {"id":188,"name":"SAINT LUCIA"},
    {"id":189,"name":"SAINT MARTIN (FRENCH PART)"},
    {"id":190,"name":"SAINT PIERRE AND MIQUELON"},
    {"id":191,"name":"SAINT VINCENT AND THE GRENADINES"},
    {"id":192,"name":"SAMOA"},
    {"id":193,"name":"SAN MARINO"},
    {"id":194,"name":"SAO TOME AND PRINCIPE"},
    {"id":195,"name":"SAUDI ARABIA"},
    {"id":196,"name":"SENEGAL"},
    {"id":197,"name":"SERBIA"},
    {"id":198,"name":"SEYCHELLES"},
    {"id":199,"name":"SIERRA LEONE"},
    {"id":200,"name":"SINGAPORE"},
    {"id":201,"name":"SINT MAARTEN (DUTCH PART)"},
    {"id":202,"name":"SLOVAKIA"},
    {"id":203,"name":"SLOVENIA"},
    {"id":204,"name":"SOLOMON ISLANDS"},
    {"id":205,"name":"SOMALIA"},
    {"id":206,"name":"SOUTH AFRICA"},
    {"id":207,"name":"SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS"},
    {"id":208,"name":"SPAIN"},
    {"id":209,"name":"SRI LANKA"},
    {"id":210,"name":"SUDAN"},
    {"id":211,"name":"SURINAME"},
    {"id":212,"name":"SVALBARD AND JAN MAYEN"},
    {"id":213,"name":"SWAZILAND"},
    {"id":214,"name":"SWEDEN"},
    {"id":215,"name":"SWITZERLAND"},
    {"id":216,"name":"SYRIAN ARAB REPUBLIC"},
    {"id":217,"name":"TAIWAN, PROVINCE OF CHINA"},
    {"id":218,"name":"TAJIKISTAN"},
    {"id":219,"name":"TANZANIA, UNITED REPUBLIC OF"},
    {"id":220,"name":"THAILAND"},
    {"id":221,"name":"TIMOR-LESTE"},
    {"id":222,"name":"TOGO"},
    {"id":223,"name":"TOKELAU"},
    {"id":224,"name":"TONGA"},
    {"id":225,"name":"TRINIDAD AND TOBAGO"},
    {"id":226,"name":"TUNISIA"},
    {"id":227,"name":"TURKEY"},
    {"id":228,"name":"TURKMENISTAN"},
    {"id":229,"name":"TURKS AND CAICOS ISLANDS"},
    {"id":230,"name":"TUVALU"},
    {"id":231,"name":"UGANDA"},
    {"id":232,"name":"UKRAINE"},
    {"id":233,"name":"UNITED ARAB EMIRATES"},
    {"id":234,"name":"UNITED KINGDOM"},
    {"id":235,"name":"UNITED STATES"},
    {"id":236,"name":"UNITED STATES MINOR OUTLYING ISLANDS"},
    {"id":237,"name":"URUGUAY"},
    {"id":238,"name":"UZBEKISTAN"},
    {"id":239,"name":"VANUATU"},
    {"id":240,"name":"VENEZUELA, BOLIVARIAN REPUBLIC OF"},
    {"id":241,"name":"VIET NAM"},
    {"id":242,"name":"VIRGIN ISLANDS, BRITISH"},
    {"id":243,"name":"VIRGIN ISLANDS, U.S."},
    {"id":244,"name":"WALLIS AND FUTUNA"},
    {"id":245,"name":"WESTERN SAHARA"},
    {"id":246,"name":"YEMEN"},
    {"id":247,"name":"ZAMBIA"},
    {"id":248,"name":"ZIMBABWE"}
];

export const orgTypes = [
    {id: "", name: "Choose..."},
    {id: "1", name: "Public limited company"},
    {id: "2", name: "Private limited company"},
    {id: "3", name: "Overseas company"},
    {id: "4", name: "Other corporate body"},
    {id: "5", name: "Partnership"},
    {id: "6", name: "Incorporated association"},
    {id: "7", name: "Sole trader"},
    {id: "8", name: "other entity"}
];

export const modeOfRecoveryOptions = [
    { id: "1", isOther: 0, name:"Lump sum"},
    { id: "2", isOther: 0, name:"Instalments"},
    { id: "3", isOther: 0, name:"Deposit plus instalments"},
    { id: "4", isOther: 1, name:"Other please give detail"},
];

export const reviewSteps = [
    "Application In Progress/ Draft",
    "Awaiting Acknowledgement",
    "Failed Acknowledgement",
    "Awaiting Review",
    "Failed 1st Review",
    "Awaiting Committee Review",
    "Awaiting Committee Final Decision",
    "Rejected by Committee",
    "Awaiting Director Decision",
    "Rejected by Director",
    "Approved",
];

export const serviceLevelTierOptions = [
    { id: "1", name:"Tier 1"},
    { id: "2", name:"Tier 2"},
    { id: "3", name:"Tier 3"},
    { id: "4", name:"Tier 4"},
    { id: "5", name:"Tier 5"},
];

export const hoursOfServiceOptions = [
    {id: 1, name:"01:00"},
    {id: 2, name:"02:00"},
    {id: 3, name:"03:00"},
    {id: 4, name:"04:00"},
    {id: 5, name:"05:00"},
    {id: 6, name:"06:00"},
    {id: 7, name:"07:00"},
    {id: 8, name:"08:00"},
    {id: 9, name:"09:00"},
    {id:10, name:"10:00"},
    {id:11, name:"11:00"},
    {id:12, name:"12:00"},
    {id:13, name:"13:00"},
    {id:14, name:"14:00"},
    {id:15, name:"15:00"},
    {id:16, name:"16:00"},
    {id:17, name:"17:00"},
    {id:18, name:"18:00"},
    {id:19, name:"19:00"},
    {id:20, name:"20:00"},
    {id:21, name:"21:00"},
    {id:22, name:"22:00"},
    {id:23, name:"23:00"},
    {id:24, name:"24:00"},
];



