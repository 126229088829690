import React, { useMemo } from "react";
import { useSitesUIContext } from "../SitesUIContext";

export function SitesGrouping() {
  // Sites UI Context
  const sitesUIContext = useSitesUIContext();
  const sitesUIProps = useMemo(() => {
    return {
      ids: sitesUIContext.ids,
      setIds: sitesUIContext.setIds,
      openDeleteSitesDialog: sitesUIContext.openDeleteSitesDialog,
      openFetchSitesDialog: sitesUIContext.openFetchSitesDialog,
      openUpdateSitesStatusDialog:
        sitesUIContext.openUpdateSitesStatusDialog,
    };
  }, [sitesUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  Selected records count: <b>{sitesUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm disabled"
                onClick={sitesUIProps.openDeleteSitesDialog}
                disabled
              >
                <i className="fa fa-trash"></i> Delete All
              </button>
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={sitesUIProps.openFetchSitesDialog}
              >
                <i className="fa fa-stream"></i> View Selected
              </button>
              {/* &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                className="btn btn-light-primary font-weight-bolder font-size-sm"
                onClick={sitesUIProps.openUpdateSitesStatusDialog}
              >
                <i className="fa fa-sync-alt"></i> Update Status
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
