import React from "react";
import { Container } from "react-bootstrap"; //, Nav, NavDropdown, Navbar
import { toAbsoluteUrl } from "../../../_helpers";

export function EpraFooter() {
    var year = new Date().getFullYear();

    return (<>
        <div className="mt-5" style={{ width: '100%' }}>
            <Container>
                <div className="row">
                    <div className="col-md-12 py-5 mx-md-5" style={{ background: `url(${toAbsoluteUrl("/media/bg/curved-footer.png")}) top left no-repeat`, height: '148px' }}>
                        <p className="text-white">
                            &copy; {year} Energy &amp; Petroleum Regulatory Authority<br />
                            <small className="opacity-80">All rights reserved</small>
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    </>)
}